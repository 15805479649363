import { render, staticRenderFns } from "./PatientPhoneWarningsTemplate.vue?vue&type=template&id=dee91866&scoped=true&"
import script from "./PatientPhoneWarningsTemplate.vue?vue&type=script&lang=js&"
export * from "./PatientPhoneWarningsTemplate.vue?vue&type=script&lang=js&"
import style0 from "./PatientPhoneWarningsTemplate.vue?vue&type=style&index=0&id=dee91866&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dee91866",
  null
  
)

export default component.exports