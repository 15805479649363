<template>
  <modal
    name="finish-send-offer-modal"
    :classes="['modal-window']"
    :click-to-close="false"
    transition="scale"
    :width="modalWidth"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal-window-content flex-column--align-center">
        <div class="modal__content">
          <span>
            {{ $t('dashboard.label.treatmentOfferWasSent') }}
          </span>
        </div>
        <button class="modal__button rounded-button-white" @click="confirmTreatmentCreation">
          {{ $t('dashboard.button.ok') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';
import { PATIENT_TAB_NAMES } from '@/modules/dashboard/api/constants';

export default {
  name: 'FinishSendOfferModal',
  data() {
    return {
      modalWidth: null
    };
  },
  computed: {
    ...mapGetters({
      currentPatientId: types.getters.CURRENT_PATIENT_ID
    })
  },
  created() {
    this.modalWidth = isMobileDevice() ? 300 : 400;
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      setActivePatientTab: types.actions.SET_ACTIVE_PATIENT_TAB,
      finishCreateTreatmentProcess: types.actions.FINISH_CREATE_TREATMENT_PROCESS,
      resetCreateTreatmentSelections: types.actions.RESET_CREATE_TREATMENT_SELECTIONS
    }),
    goToTreatmentHistory() {
      if (isMobileDevice()) {
        this.$router.push({
          name: 'TreatmentHistory',
          params: {
            id: this.currentPatientId
          }
        });
      }

      this.setActivePatientTab(PATIENT_TAB_NAMES.TREATMENT_HISTORY);
      this.$modal.hide('finish-send-offer-modal');
    },
    async confirmTreatmentCreation() {
      this.setLoading(true);
      await this.finishCreateTreatmentProcess();
      this.setLoading(false);

      this.goToTreatmentHistory();
      await this.resetCreateTreatmentSelections();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/common/buttons';

.modal-window-content {
  padding: 30px;
}

.modal {
  &__content {
    margin-bottom: 30px;
    padding: 30px 15px;
    font-size: 13px;
    font-weight: 300;
    color: $text-color;
    background-color: $beige;
    border-radius: 8px;
  }

  &__button {
    min-width: 80px;
  }
}
</style>
