<template>
  <modal
    name="unsaved-changes-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :click-to-close="canClickToClose"
    :width="300"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <div class="modal-window-content flex-column--align-center">
        <p class="title ie-text-modal">{{ $t('dashboard.label.saveChanges') }}</p>
        <div class="full-width flex--align-center option-buttons space-between">
          <a class="option-buttons__cancel cancel-button" @click="onCancel">
            {{ $t('dashboard.action.cancel') }}
          </a>
          <button class="option-buttons__no rounded-button-gray" @click="onUnsave">
            {{ $t('dashboard.medicalData.no') }}
          </button>
          <button class="option-buttons__yes rounded-button-white" @click="onSave">
            {{ $t('dashboard.medicalData.yes') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { isMobileDevice } from '@/modules/dashboard/utils';

import { UNSAVED_CHANGES_INPUTS } from '@/modules/dashboard/api/constants';

export default {
  name: 'UnsavedChangesModal',
  data() {
    return {
      resolveAnswer: null,
      canClickToClose: !isMobileDevice()
    };
  },
  methods: {
    beforeOpen({ params: { resolveAnswer } }) {
      this.resolveAnswer = resolveAnswer;
    },
    hideModal() {
      this.$modal.hide('unsaved-changes-modal');
    },
    onCancel() {
      this.resolveAnswer(UNSAVED_CHANGES_INPUTS.CANCEL);

      this.hideModal();
    },
    onUnsave() {
      this.resolveAnswer(UNSAVED_CHANGES_INPUTS.NO);

      this.hideModal();
    },
    onSave() {
      this.resolveAnswer(UNSAVED_CHANGES_INPUTS.YES);

      this.hideModal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.modal-window-content {
  box-sizing: border-box;
  padding: 30px 25px;

  background-color: $white;
  border-radius: 8px;

  font-size: 15px;
}

.title {
  margin-bottom: 30px;

  text-align: center;

  color: $text-color;
}

.cancel-button {
  text-decoration: none;

  color: $cancel-text-color;
}

.option-buttons {
  &__no {
    color: $text-color;
  }
}

@media (max-width: 767px) {
  .modal-window-content {
    font-size: 15px;
    line-height: 17px;
  }

  .option-buttons {
    &__cancel,
    &__no,
    &__yes {
      font-size: 15px;
      line-height: 17px;
    }

    &__no,
    &__yes {
      min-width: 80px;

      padding: 9px 10px;
    }
  }
}
</style>
