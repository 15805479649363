<template>
  <div class="notification-template-treatment-composition">
    <notification-template-treatment-composition-element
      v-for="treatmentCompositionElement in treatmentComposition"
      :key="treatmentCompositionElement.name"
      class="notification-template-treatment-composition__element"
      :treatment-composition-element="treatmentCompositionElement"
      :language="language"
    />
  </div>
</template>

<script>
import NotificationTemplateTreatmentCompositionElement from '@/modules/dashboard/components/message-templates/notification/NotificationTemplateTreatmentCompositionElement';

export default {
  name: 'NotificationTemplateTreatmentComposition',
  components: { NotificationTemplateTreatmentCompositionElement },
  props: {
    language: {
      type: String,
      required: true
    },
    treatmentComposition: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.notification-template-treatment-composition {
  &__element {
    padding: 15px 0 10px 0;
    border-bottom: 1px solid $westart-color;

    &:last-child {
      border-color: transparent;
    }
  }
}
</style>
