<template>
  <modal
    name="finish-treatment-validation-modal"
    class="finish-treatment-validation-modal"
    :classes="['modal-window']"
    :click-to-close="false"
    transition="scale"
    :width="modalWidth"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <div class="modal-window-content flex-column--align-center">
        <div class="warning-message">
          <div class="warning-message__text pre-wrap">
            <span>{{ newTreatmentCreatedMessage }}</span>
          </div>
        </div>
        <div class="description-message flex-column--align-center">
          <div class="description-message__text">
            {{ $t('dashboard.label.addFormulaDetails') }}
          </div>
          <button
            class="treatment-details__footer-button rounded-button-blue button_pdf"
            @click="openInciLabel"
          >
            <span class="icon-button icon-pdf-button"></span>
            {{ $t('dashboard.action.printLabel') }}
          </button>
        </div>
        <button class="rounded-button-white" @click="confirmTreatmentCreation">
          {{ $t('dashboard.button.ok') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { getInciPdfLabelAsBlob, getTreatmentDaysBeforeStart } from '@/modules/dashboard/api';

import { isMobileDevice, openFileInNewTab } from '@/modules/dashboard/utils';
import { getDaysTranslationWithNumber } from '@/modules/dashboard/utils/date-utils';
import { isAppleMobileDevice } from '@/utils/featureDetection';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import { PATIENT_TAB_NAMES } from '@/modules/dashboard/api/constants';

export default {
  name: 'FinishTreatmentValidationModal',
  data() {
    return {
      modalWidth: null,
      daysBeforeTreatmentStart: 0
    };
  },
  computed: {
    ...mapGetters({
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID,
      currentPatientFullName: dashTypes.getters.CURRENT_PATIENT_FULL_NAME,
      treatmentId: dashTypes.getters.SUMMARY_TREATMENT_ID
    }),
    newTreatmentCreatedMessage() {
      const startDaysCounter = getDaysTranslationWithNumber(this.$t, this.daysBeforeTreatmentStart);
      const translationArguments = [this.currentPatientFullName, startDaysCounter];

      const hasNoTranslationWithNumberTranslationKey =
        this.daysBeforeTreatmentStart === 1
          ? 'dashboard.label.newTreatmentCreatedStartsTomorrow'
          : 'dashboard.label.newTreatmentCreatedStartsToday';

      return this.daysBeforeTreatmentStart > 1
        ? this.$t('dashboard.label.newTreatmentCreated', translationArguments)
        : this.$t(hasNoTranslationWithNumberTranslationKey, [this.currentPatientFullName]);
    }
  },
  created() {
    this.modalWidth = isMobileDevice() ? 300 : 500;
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      setActivePatientTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB,
      finishCreateTreatmentProcess: dashTypes.actions.FINISH_CREATE_TREATMENT_PROCESS,
      resetCreateTreatmentSelections: dashTypes.actions.RESET_CREATE_TREATMENT_SELECTIONS
    }),
    async beforeOpen() {
      const {
        data: { numberOfDaysBeforeTreatmentStart }
      } = await getTreatmentDaysBeforeStart(this.currentPatientId, this.treatmentId);

      this.daysBeforeTreatmentStart = numberOfDaysBeforeTreatmentStart;
    },
    async goToTreatmentHistory() {
      if (isMobileDevice()) {
        await this.$router.push({
          name: 'TreatmentHistory',
          params: {
            id: this.currentPatientId
          }
        });
      }

      this.setActivePatientTab(PATIENT_TAB_NAMES.TREATMENT_HISTORY);
      this.$modal.hide('finish-treatment-validation-modal');
    },
    async confirmTreatmentCreation() {
      this.setLoading(true);
      await this.finishCreateTreatmentProcess();
      this.setLoading(false);

      await this.goToTreatmentHistory();
      await this.resetCreateTreatmentSelections();
    },
    async openInciLabel() {
      this.setLoading(true);
      const blob = await getInciPdfLabelAsBlob({
        patientId: this.currentPatientId,
        treatmentId: this.treatmentId
      });

      if (isAppleMobileDevice()) {
        await this.goToTreatmentHistory();
        await this.finishCreateTreatmentProcess();
        await this.setActivePatientTab(PATIENT_TAB_NAMES.TREATMENT_HISTORY);
      }

      openFileInNewTab(blob);

      this.setLoading(false);

      if (isAppleMobileDevice()) {
        this.resetCreateTreatmentSelections();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.title {
  font-size: 13px;
  line-height: 19px;
  color: $text-color;

  margin: 60px 0 40px 0;
}

.warning-message {
  margin-top: 30px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 360px;
  padding: 8px 17px;
  background-color: $beige;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 200;
  line-height: 19px;
  color: $text-color;

  &__text {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 18px;

    &:first-child {
      margin-top: 20px;
    }
  }
}

.description-message {
  width: 100%;
  max-width: 360px;
  padding: 20px 15px;
  margin-bottom: 20px;
  border: 1px solid $separator-grey-color;
  border-radius: 8px;

  &__text {
    max-width: 100%;
    font-size: 13px;
    line-height: 19px;
    font-weight: 200;
    margin-bottom: 15px;
  }
}

.rounded-button-white {
  margin-bottom: 26px;
}

@media (max-width: 767px) {
  .finish-treatment-validation-modal {
    .modal-container {
      padding: 0 20px;
    }
  }

  .description-message {
    &__text {
      margin-bottom: 26px;
      font-size: 12px;
    }
  }
}
</style>
