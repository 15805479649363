<template>
  <div v-if="directionsOfUse" class="notification-template-directions-of-use">
    <div v-if="directionsOfUse.morningRoutine">
      <div class="notification-template-directions-of-use__title">
        {{ $t('messageTemplateTranslations.label.morningRoutine', language) }}:
      </div>
      <div class="notification-template-directions-of-use__direction">
        {{ directionsOfUse.morningRoutine }}
      </div>
    </div>

    <div v-if="directionsOfUse.eveningRoutine">
      <div class="notification-template-directions-of-use__title">
        {{ $t('messageTemplateTranslations.label.eveningRoutine', language) }}:
      </div>
      <div class="notification-template-directions-of-use__direction">
        {{ directionsOfUse.eveningRoutine }}
      </div>
    </div>

    <div v-if="hasHowToApply">
      <div class="notification-template-directions-of-use__title">
        {{ $t('messageTemplateTranslations.label.howToApplyProducts', language) }}:
      </div>
      <div
        v-for="{ name, description } in directionsOfUse.howToApply"
        :key="name"
        class="notification-template-directions-of-use__direction"
      >
        <span v-if="name">{{ name }}:</span>
        <span v-if="description">{{ description }}</span>
      </div>
    </div>

    <div v-if="hasTipsAndTricks">
      <div class="notification-template-directions-of-use__title">
        {{ $t('messageTemplateTranslations.label.tipsAndTricks', language) }}:
      </div>
      <div
        v-for="tip in directionsOfUse.tipsAndTricks"
        :key="tip"
        class="notification-template-directions-of-use__direction"
      >
        {{ tip }}
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';

import { getTreatmentDirectionsOfUse } from '@/modules/dashboard/api';
import { getParsedHowToApply } from '@/modules/dashboard/utils/create-treatment-utils';

export default {
  name: 'NotificationTemplateTreatmentDirectionsOfUse',
  props: {
    patientId: {
      type: Number,
      required: true
    },
    treatmentId: {
      type: Number,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      directionsOfUse: null
    };
  },
  computed: {
    hasHowToApply() {
      return !isEmpty(this.directionsOfUse.howToApply);
    },
    hasTipsAndTricks() {
      return !isEmpty(this.directionsOfUse.tipsAndTricks);
    }
  },
  async mounted() {
    if (!this.treatmentId) {
      return;
    }

    const { data: directionsOfUse } = await getTreatmentDirectionsOfUse({
      patientId: this.patientId,
      treatmentId: this.treatmentId,
      language: this.language
    });

    this.directionsOfUse = {
      ...directionsOfUse,
      howToApply: getParsedHowToApply(directionsOfUse)
    };
  }
};
</script>

<style lang="scss" scoped>
.notification-template-directions-of-use {
  &__title {
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__direction {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  }
}
</style>
