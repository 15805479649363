<template>
  <section class="notification-template-treatment-details">
    <div class="notification-template-treatment-details__section">
      <h2 class="notification-template-treatment-details__title">
        {{ $t('messageTemplateTranslations.label.personalizedTreatment', language) }}
      </h2>
      <notification-template-treatment-composition
        :language="language"
        :treatment-composition="treatmentComposition"
      />
    </div>
    <div class="notification-template-treatment-details__section">
      <h2 class="notification-template-treatment-details__title">
        {{ $t('messageTemplateTranslations.label.directionsOfUse', language) }}
      </h2>
      <notification-template-treatment-directions-of-use
        :key="language"
        :patient-id="patientId"
        :treatment-id="treatmentId"
        :language="language"
      />
    </div>
  </section>
</template>

<script>
import { loadMessageTemplateTranslations } from '@/i18n';

import NotificationTemplateTreatmentComposition from '@/modules/dashboard/components/message-templates/NotificationTemplateTreatmentComposition';
import NotificationTemplateTreatmentDirectionsOfUse from '@/modules/dashboard/components/message-templates/NotificationTemplateTreatmentDirectionsOfUse';

export default {
  name: 'NotificationTemplateTreatmentDetails',
  components: {
    NotificationTemplateTreatmentDirectionsOfUse,
    NotificationTemplateTreatmentComposition
  },
  props: {
    patientId: {
      type: Number,
      required: true
    },
    treatmentId: {
      type: Number,
      required: true
    },
    language: {
      type: String,
      required: true
    },
    treatmentComposition: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    language: {
      immediate: true,
      handler: loadMessageTemplateTranslations
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.notification-template-treatment-details {
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin: 0 0 20px;
  }

  &__section {
    padding: 30px 40px;
    background-color: $soapstone-color;
    border-top: 1px solid $westart-color;
  }
}

@media (max-width: 767px) {
  .notification-template-treatment-details {
    &__section {
      padding: 20px 0;
    }
  }
}
</style>
