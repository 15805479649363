<template>
  <div v-if="shouldShowPatientPhoneWarning" class="patient-warning__container flex--center--center">
    <div class="patient-warning">
      {{ patientPhoneWarningMessage }}
    </div>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PatientPhoneWarningsTemplate',
  inject: ['$validator'],
  props: {
    patient: {
      type: Object,
      required: true
    },
    shouldShowWarnings: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    shouldShowPatientPhoneWarning() {
      const { phone, smsAccepted } = this.patient;

      return (!phone || !smsAccepted) && this.shouldShowWarnings;
    },
    patientPhoneWarningMessage() {
      const patientPhoneWarningKeySuffix = this.patient.phone
        ? 'patientNotAcceptSms'
        : 'noPhoneNumber';

      return this.$t(`dashboard.warning.${patientPhoneWarningKeySuffix}`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';

.patient-warning {
  max-width: 260px;
  padding: 29px 23px;

  background: $beige;
  border-radius: 8px;

  font-size: 11px;
  line-height: 150%;
  font-weight: 100;

  text-align: center;
  color: $text-color;
}
</style>
