<template>
  <div class="invite-tabs-container">
    <mobile-header>
      <span slot="header-title" class="header-title ellipsized">
        {{ mobileHeaderLabel }}
      </span>
    </mobile-header>
    <tabs
      class="send-patient-invite"
      :tabs="availableTabs"
      :active-tab="activeInviteTab"
      is-labels-are-keys
      @select-tab="selectTab"
    >
      <div
        ref="reviewMessageTabsContent"
        slot="tab-content"
        class="review-patient-message-tabs-content"
      >
        <patient-phone-warnings-template
          class="review-patient-message-tabs-content__patient-phone-warnings"
          :patient="currentPatient"
          :should-show-warnings="shouldShowPatientPhoneWarnings"
        >
          <notification-template
            :key="messageTemplateType"
            class="review-patient-message-tabs-content__notification-template"
            :notification-type="notificationType"
            :template-type="messageTemplateType"
            :custom-template-data="customTemplateData"
            :default-language="language"
            @change-language="updateLanguage"
            @update-custom-template-data="updateTemplate"
          >
            <notification-template-treatment-details
              slot="treatment-details"
              :patient-id="currentPatientId"
              :treatment-id="treatmentId"
              :language="language"
              :treatment-composition="treatmentComposition"
            />
          </notification-template>
        </patient-phone-warnings-template>
      </div>
    </tabs>
    <div class="send-patient-email__footer flex--align-center">
      <button
        class="rounded-button-orange rounded-button-orange--filled"
        @click="onSendInviteClick"
      >
        {{ sendInviteButtonLabel }}
      </button>

      <button
        :hidden="isDTCNDCheckbox"
        class="rounded-button-orange rounded-button-orange--filled"
        @click="onSendInvite1Click"
      >
        {{ sendInviteNoNotifyLabel }}
      </button>
    </div>
    <mobile-fixed-footer class="result__footer result-footer flex--center--center">
      <button
        slot="footer"
        class="result-footer__button rounded-button-orange rounded-button-orange--filled"
        @click="onSendInviteClick"
      >
        {{ sendInviteButtonLabel }}
      </button>
    </mobile-fixed-footer>

    <finish-treatment-validation-modal v-if="isMobileDevice()" />
    <finish-send-offer-modal v-if="isMobileDevice()" />
    <invite-template-sent-modal />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import { pick } from 'lodash';

import TabInfo from '@/modules/dashboard/components/common/TabInfo';

import Tabs from '@/modules/dashboard/components/common/Tabs';
import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import MobileFixedFooter from '@/modules/dashboard/components/dashboard/common/MobileFixedFooter';
import FinishTreatmentValidationModal from '@/modules/dashboard/components/create-treatment-tab/FinishTreatmentValidationModal';
import InviteTemplateSentModal from '@/modules/dashboard/components/dashboard/invite-patient/InviteTemplateSentModal';
import FinishSendOfferModal from '@/modules/dashboard/components/create-treatment-tab/send-offer/FinishSendOfferModal';
import NotificationTemplate from '@/modules/dashboard/components/message-templates/NotificationTemplate';
import PatientPhoneWarningsTemplate from '@/modules/dashboard/components/patient-card/common/PatientPhoneWarningsTemplate';
import NotificationTemplateTreatmentDetails from '@/modules/dashboard/components/message-templates/NotificationTemplateTreatmentDetails';

import {
  finishCreatingTreatmentProcess,
  resendTreatmentOffer,
  getNewTreatmentNotificationTranslation,
  updatePatientInfo
} from '@/modules/dashboard/api';

import { isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  DEFAULT_PERFECT_SCROLLBAR_OPTIONS,
  INVITE_TAB_NAMES,
  UPDATE_PATIENT_FIELDS,
  REVIEW_TREATMENT_TEMPLATE_TYPE,
  REVIEW_TREATMENT_MESSAGE_TYPE,
  REVIEW_TREATMENT_MESSAGE_MODAL_NAMES,
  MESSAGE_TEMPLATE_TYPES,
  EMAIL_TEMPLATE_DATA_DEFAULT_VALUES,
  MESSAGE_SECTION_VALUES
} from '@/modules/dashboard/api/constants';

const TABS_BINDING_EMAIL_MESSAGE = [
  new TabInfo(
    INVITE_TAB_NAMES.EMAIL_MESSAGE,
    'dashboard.messageEmail',
    'SendPatientInviteWithEmail'
  )
];

const TABS_BINDING_EMAIL_SMS_MESSAGE = [
  new TabInfo(
    INVITE_TAB_NAMES.EMAIL_MESSAGE,
    'dashboard.messageEmail',
    'SendPatientInviteWithEmail'
  ),
  new TabInfo(
    INVITE_TAB_NAMES.SMS_MESSAGE,
    'dashboard.messageSms',
    'SendPatientInviteWithTextMessage'
  )
];

export default {
  name: 'ReviewPatientInviteTabs',
  components: {
    NotificationTemplateTreatmentDetails,
    PatientPhoneWarningsTemplate,
    NotificationTemplate,
    FinishSendOfferModal,
    InviteTemplateSentModal,
    FinishTreatmentValidationModal,
    MobileHeader,
    MobileFixedFooter,
    Tabs
  },
  props: {
    templateType: {
      type: String,
      required: true
    },
    isNotificationCancelled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      notificationType: REVIEW_TREATMENT_TEMPLATE_TYPE[this.templateType],
      messageTemplateType: MESSAGE_TEMPLATE_TYPES.EMAIL,
      modalNames: REVIEW_TREATMENT_MESSAGE_MODAL_NAMES[this.templateType],
      templateDataEmail: { ...EMAIL_TEMPLATE_DATA_DEFAULT_VALUES },
      templateDataSMS: { ...MESSAGE_SECTION_VALUES },
      activeInviteTab: INVITE_TAB_NAMES.EMAIL_MESSAGE,
      language: '',
      DTCND: null,
      scrollbar: null,
      notificationCancelled: this.isNotificationCancelled
    };
  },
  computed: {
    ...mapGetters({
      userLocale: rootTypes.getters.GET_USER_LANGUAGE,
      user: rootTypes.getters.GET_USER,
      hasWebshopAccess: rootTypes.getters.HAS_WEBSHOP_ACCESS,
      treatmentId: dashTypes.getters.SUMMARY_TREATMENT_ID,
      patientLocale: dashTypes.getters.CURRENT_PATIENT_LOCALE,
      currentPatient: dashTypes.getters.CURRENT_PATIENT,
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID,
      getTreatmentComposition: dashTypes.getters.SUMMARY_TREATMENT_COMPOSITION
    }),
    treatmentComposition() {
      return this.getTreatmentComposition({});
    },
    isCreateTreatmentTemplate() {
      return this.templateType === REVIEW_TREATMENT_MESSAGE_TYPE.CREATE_TREATMENT;
    },
    mobileHeaderLabel() {
      const keyPostfix = this.isCreateTreatmentTemplate
        ? 'reviewTreatmentMessages'
        : 'reviewTreatmentOffer';

      return this.$t(`dashboard.label.${keyPostfix}`);
    },
    sendInviteButtonLabel() {
      const keyPostfix = this.isCreateTreatmentTemplate
        ? 'sendAndCreateTreatment'
        : 'sendTreatmentOffer';

      return this.$t(`dashboard.action.${keyPostfix}`);
    },
    sendInviteNoNotifyLabel() {
      return this.$t('dashboard.action.sendTreatmentNoNotification');
    },
    isDTCNDCheckbox() {
      return !this.DTCND;
    },
    customTemplateData() {
      return this.activeInviteTab === INVITE_TAB_NAMES.EMAIL_MESSAGE
        ? this.templateDataEmail
        : this.templateDataSMS;
    },
    shouldShowPatientPhoneWarnings() {
      return this.messageTemplateType === MESSAGE_TEMPLATE_TYPES.SMS;
    },
    availableTabs() {
      return this.isCreateTreatmentTemplate
        ? TABS_BINDING_EMAIL_MESSAGE
        : TABS_BINDING_EMAIL_SMS_MESSAGE;
    }
  },
  created() {
    this.language = this.patientLocale;
    this.DTCND = this.user.disablePFEN;
  },
  mounted() {
    this.scrollbar = new PerfectScrollbar(
      '.review-patient-message-tabs-content',
      DEFAULT_PERFECT_SCROLLBAR_OPTIONS
    );

    this.loadEmailTemplateDataIfNotSelected();
  },
  destroyed() {
    this.setActiveInviteTab(INVITE_TAB_NAMES.EMAIL_MESSAGE);
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      setActiveInviteTab: dashTypes.actions.SET_ACTIVE_INVITE_TAB,
      updatePatientInfo: dashTypes.actions.UPDATE_PATIENT_INFO
    }),
    isMobileDevice,
    async updatePatientLanguage() {
      const patientData = {
        ...pick(this.currentPatient, UPDATE_PATIENT_FIELDS),
        locale: this.language
      };

      await updatePatientInfo(this.currentPatient.id, patientData);
    },
    async onSendInvite1Click() {
      this.notificationCancelled = true;
      this.onSendInviteClick();
    },
    async onSendInviteClick() {
      this.setLoading(true);

      if (this.templateType === REVIEW_TREATMENT_MESSAGE_TYPE.RESEND_OFFER) {
        await this.resendTreatmentOfferWithUpdates();
      } else {
        await this.sendBothInvite();
      }

      this.setLoading(false);

      if (!this.hasErrors) {
        this.$modal.hide(this.modalNames.parentModalName);
        this.$modal.show(this.modalNames.finishModalName);
      }

      await this.updatePatientLanguage();
    },
    async sendBothInvite() {
      await this.loadEmailTemplateDataIfNotSelected();
      await this.loadSMSTemplateDataIfNotSelected();

      await finishCreatingTreatmentProcess(this.getSendMessageTemplatePayload());
    },
    async resendTreatmentOfferWithUpdates() {
      await this.loadEmailTemplateDataIfNotSelected();
      await this.loadSMSTemplateDataIfNotSelected();

      await resendTreatmentOffer(this.getSendMessageTemplatePayload());
    },
    getSendMessageTemplatePayload() {
      return {
        patientId: this.currentPatientId,
        languageTag: this.language,
        treatmentId: this.treatmentId,
        messageTemplates: [this.templateDataEmail, this.templateDataSMS],
        cancelNotifications: this.notificationCancelled
      };
    },
    async loadNotificationTemplate(wayOfSend) {
      const { data } = await getNewTreatmentNotificationTranslation({
        patientId: this.currentPatientId,
        languageTag: this.language,
        treatmentId: this.treatmentId,
        messageType: this.notificationType,
        wayOfSend
      });

      return data;
    },
    async loadEmailTemplateDataIfNotSelected() {
      if (this.templateDataEmail && this.templateDataEmail.language === this.language) {
        return;
      }

      this.templateDataEmail = await this.loadNotificationTemplate(MESSAGE_TEMPLATE_TYPES.EMAIL);
    },
    async loadSMSTemplateDataIfNotSelected() {
      if (this.templateDataSMS && this.templateDataSMS.language === this.language) {
        return;
      }

      this.templateDataSMS = await this.loadNotificationTemplate(MESSAGE_TEMPLATE_TYPES.SMS);
    },
    selectTab(selectedTabName) {
      this.activeInviteTab = selectedTabName;
      this.$refs.reviewMessageTabsContent.scrollTop = 0;

      if (selectedTabName === INVITE_TAB_NAMES.EMAIL_MESSAGE) {
        this.messageTemplateType = MESSAGE_TEMPLATE_TYPES.EMAIL;
        this.loadEmailTemplateDataIfNotSelected();

        return;
      }

      this.messageTemplateType = MESSAGE_TEMPLATE_TYPES.SMS;
      this.loadSMSTemplateDataIfNotSelected();
    },
    updateTemplate(templateData) {
      if (this.activeInviteTab === INVITE_TAB_NAMES.EMAIL_MESSAGE) {
        this.templateDataEmail = templateData;

        return;
      }

      this.templateDataSMS = templateData;
    },
    updateLanguage(language) {
      this.language = language;

      this.loadEmailTemplateDataIfNotSelected();
      this.loadSMSTemplateDataIfNotSelected();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/icons.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

.send-patient-email {
  font-size: 15px;
  line-height: 17px;
  color: $text-color;
  position: relative;

  &__header {
    height: 50px;
    padding: 0 20px 0 25px;
  }

  &__footer {
    padding: 16px 25px;
    border-top: 1px solid $dim-white;

    .rounded-button-orange {
      margin: 0 auto;

      &.disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  &__email {
    margin: 30px 0 20px 0;

    &__title {
      margin-bottom: 15px;
      font-size: 13px;
      line-height: 15px;
    }
  }
}

.review-patient-message-tabs-content {
  height: 530px;
  position: relative;
  max-height: calc(100vh - 170px);

  &__patient-phone-warnings {
    min-height: 100%;
  }

  & &__notification-template {
    padding: 0 25px;
  }
}

.rounded-input {
  width: auto;
  min-height: 36px;
}

/deep/ .send-patient-invite .tabs__container {
  margin: 0 25px 20px;
  width: auto;
}

/deep/ .send-patient-invite .tabs {
  justify-content: flex-start;
  width: 100%;

  .tab {
    position: relative;
    top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-right: 20px;
  }
}

.invite-tabs-container .mobile-header {
  background-color: $background;
}

.result__footer {
  display: none;
  height: 70px;

  background-color: $white;
  border-top: 1px solid $dim-white;
  box-shadow: 0px -6px 7px rgba(148, 166, 176, 0.15);
}

@media (max-width: 767px) {
  /deep/ .send-patient-invite .tabs__container {
    margin: 0 0 25px;
  }

  /deep/ .send-patient-invite .tabs {
    .tab {
      top: 0;
      padding: 28px 0 7px;

      .no-search-field {
        position: relative;
        padding-left: 25px;

        &::before {
          display: none;
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 15px;
          height: 15px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &:first-child {
        .no-search-field {
          &::before {
            display: block;
            background-image: url('~@/assets/images/dashboard/icons/email.svg');
          }
        }
      }

      &:last-child {
        margin-right: 0;

        .no-search-field {
          &::before {
            display: block;
            background-image: url('~@/assets/images/dashboard/icons/sms.svg');
          }
        }
      }

      &--active {
        &:first-child {
          .no-search-field {
            &::before {
              background-image: url('~@/assets/images/dashboard/icons/email-orange.svg');
            }
          }
        }

        &:last-child {
          .no-search-field {
            &::before {
              background-image: url('~@/assets/images/dashboard/icons/sms-orange.svg');
            }
          }
        }
      }
    }
  }

  .review-patient-message-tabs-content {
    height: calc(100vh - 190px);
    padding-bottom: 40px;
    min-height: unset;

    & &__notification-template {
      padding: 0 5px;
    }
  }

  .result__footer {
    display: flex;
  }

  .send-patient-invite {
    background-color: $white;
    margin-top: 46px;
    min-height: 100vh;
  }

  /deep/ .rounded-input {
    margin-bottom: 0;
  }

  .header-title {
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
  }

  .result-footer__button {
    font-size: 16px;
    line-height: 19px;
  }

  .send-patient-email {
    font-size: 13px;
    line-height: 15px;
    background-color: $white;

    &__header {
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 0 15px;
      font-size: 13px;
      line-height: 15px;
      color: $main-title-color;
      background-color: $background;

      .icon-close {
        margin-right: 16px;
      }
    }

    &__footer {
      display: none;
      justify-content: center;
      flex-wrap: wrap;
      padding: 16px 20px;
      box-shadow: 0px -6px 7px rgba(148, 166, 176, 0.15);

      .rounded-button-orange {
        margin-right: 0;
        font-size: 13px;
        margin-bottom: 10px;

        &__container {
          width: 100%;
        }

        &:first-child {
          margin-right: 20px;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
</style>
