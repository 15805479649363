<template>
  <div class="treatment-composition-element flex--align-center">
    <img
      class="treatment-composition-element__image"
      :src="productImageSource"
      :alt="treatmentCompositionElement.productName"
    />
    <div class="treatment-composition-element__details composition-details flex-column">
      <div class="composition-details__title">{{ nameLabel }}</div>
      <div
        v-for="(description, index) in treatmentCompositionElement.descriptions"
        :key="index"
        class="composition-details__description"
      >
        {{ getProductDescription(description) }}
      </div>
      <div
        v-if="treatmentCompositionElement.microneedling"
        class="composition-details__microneedling-description microneedling-description"
      >
        <span class="microneedling-description__title">
          {{ $t('dashboard.microneedling.step') }}
        </span>
        {{ microneedlingDescription }}
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';

import { getIngredientsDescription } from '@/modules/dashboard/utils/create-treatment-utils';
import { formatNumberWithOneDecimalPart, getProductName } from '@/modules/dashboard/utils';

import {
  PRODUCT_NAMES,
  SERUM_USAGE_ABBREVIATION,
  TREATMENT_COMPOSITION_PRODUCT_TYPE,
  CREATE_TREATMENT_SECTION_NAMES,
  PRODUCT_REFERENCES
} from '@/modules/dashboard/api/constants';

const PRODUCT_IMAGES_SOURCES_NAMES = {
  [PRODUCT_NAMES.TREAT_FACE]: 'faceSerumPack',
  [PRODUCT_NAMES.TREAT_EYES]: 'eyeSerum',
  [PRODUCT_NAMES.NEXULTRA_1]: 'nexUltra1',
  [PRODUCT_NAMES.NEXULTRA_2]: 'nexUltra2',
  [PRODUCT_NAMES.NEXULTRA_3]: 'nexUltra3',
  [PRODUCT_NAMES.NEXULTRA_B]: 'nexUltraB',
  [PRODUCT_NAMES.NEXULTRA_L]: 'nexUltraL',
  [PRODUCT_NAMES.NEXULTRA_O]: 'nexUltraO',
  [PRODUCT_NAMES.NEXULTRA_UV]: 'nexUltraUV',
  [PRODUCT_NAMES.NEXULTRA_L_BRUSH]: 'nexUltraLBrush',
  [PRODUCT_NAMES.NEXULTRA_Z]: 'nexUltraZ',
  [PRODUCT_NAMES.UNIVERSKIN_H]: 'universkinH',
  [PRODUCT_NAMES.UNIVERSKIN_M]: 'universkinM',
  [PRODUCT_NAMES.NEXULTRA_P_LIGHT]: 'nexUltraPLight',
  [PRODUCT_NAMES.NEXULTRA_P_RICH]: 'nexUltraPRich'
};

const PRODUCT_TITLE = {
  [PRODUCT_NAMES.TREAT_FACE]: 'messageTemplateTranslations.product.treatFace',
  [PRODUCT_NAMES.TREAT_EYES]: 'messageTemplateTranslations.product.treatEyes',
  [PRODUCT_NAMES.NEXULTRA_1]: 'messageTemplateTranslations.product.nexUltra1',
  [PRODUCT_NAMES.NEXULTRA_2]: 'messageTemplateTranslations.product.nexUltra2',
  [PRODUCT_NAMES.NEXULTRA_3]: 'messageTemplateTranslations.product.nexUltra3',
  [PRODUCT_NAMES.NEXULTRA_B]: 'messageTemplateTranslations.product.nexUltraB',
  [PRODUCT_NAMES.NEXULTRA_L]: 'messageTemplateTranslations.product.nexUltraL',
  [PRODUCT_NAMES.NEXULTRA_O]: 'messageTemplateTranslations.product.nexUltraO',
  [PRODUCT_NAMES.NEXULTRA_UV]: 'messageTemplateTranslations.product.nexUltraUV',
  [PRODUCT_NAMES.NEXULTRA_L_BRUSH]: 'messageTemplateTranslations.product.nexultraLBrush',
  [PRODUCT_NAMES.NEXULTRA_Z]: 'messageTemplateTranslations.product.nexUltraZ',
  [PRODUCT_NAMES.UNIVERSKIN_H]: 'messageTemplateTranslations.product.universkinH',
  [PRODUCT_NAMES.UNIVERSKIN_M]: 'messageTemplateTranslations.product.universkinM',
  [PRODUCT_NAMES.NEXULTRA_P_LIGHT]: 'messageTemplateTranslations.product.nexUltraPLight',
  [PRODUCT_NAMES.NEXULTRA_P_RICH]: 'messageTemplateTranslations.product.nexUltraPRich'
};

export const PRODUCT_DESCRIPTION_KEY = {
  [CREATE_TREATMENT_SECTION_NAMES.CLEANSE]: 'messageTemplateTranslations.label.cleanse',
  [CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE]: 'messageTemplateTranslations.label.moisturize',
  [CREATE_TREATMENT_SECTION_NAMES.PROTECT]: {
    [PRODUCT_REFERENCES.NEXULTRA_Z]: 'messageTemplateTranslations.label.protect',
    [PRODUCT_REFERENCES.NEXULTRA_UV]: 'messageTemplateTranslations.label.protect',
    [PRODUCT_REFERENCES.NEXULTRA_L_BRUSH]: 'messageTemplateTranslations.label.protect',
    [PRODUCT_REFERENCES.UNIVERSKIN_M]: 'messageTemplateTranslations.label.universkinM'
  }
};

export default {
  name: 'NotificationTemplateTreatmentCompositionElement',
  props: {
    treatmentCompositionElement: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  computed: {
    productName() {
      return getProductName(this.treatmentCompositionElement.productName);
    },
    productImageSource() {
      const numberOfCompositionDescriptions = get(
        this.treatmentCompositionElement,
        'descriptions.length'
      );

      const productImageName =
        this.productName === PRODUCT_NAMES.TREAT_FACE && numberOfCompositionDescriptions !== 2
          ? PRODUCT_IMAGES_SOURCES_NAMES[PRODUCT_NAMES.TREAT_EYES]
          : PRODUCT_IMAGES_SOURCES_NAMES[this.productName];

      return `https://skinxs-email-resources.s3.eu-west-3.amazonaws.com/shared/products/${productImageName}.png`;
    },
    nameLabel() {
      return this.$t(PRODUCT_TITLE[this.productName], this.language);
    },
    microneedlingDescription() {
      const { usage, needleSize, volume } = this.microneedlingConfig;
      const usageAbbreviation = SERUM_USAGE_ABBREVIATION[usage];
      const formattedNeedleSize = formatNumberWithOneDecimalPart(needleSize);

      return `(${usageAbbreviation} serum, ${volume}ml, ${formattedNeedleSize}mm)`;
    },
    microneedlingConfig() {
      return this.treatmentCompositionElement.microneedling || {};
    }
  },
  methods: {
    getProductDescription(description) {
      if (description.productType === TREATMENT_COMPOSITION_PRODUCT_TYPE.COMPLEMENTARY_CARE) {
        return this.getComplementaryCareProductDescription(description);
      }

      return this.getSerumProductDescription(description);
    },
    getComplementaryCareProductDescription({ usage, additionalData, size, sizeMeasurement }) {
      const [sectionName] = additionalData;
      const sectionNameKey =
        PRODUCT_DESCRIPTION_KEY[sectionName][this.productName] ||
        PRODUCT_DESCRIPTION_KEY[sectionName];

      const description = [this.$t(sectionNameKey, this.language)];

      if (usage) {
        description.push(usage);
      }

      if (size && sizeMeasurement) {
        description.push(this.getSizeWithSizeMeasurement(size, sizeMeasurement));
      }

      return description.join(', ');
    },
    getSerumProductDescription({ usage, additionalData, size, sizeMeasurement }) {
      const serumLabel = this.$t('messageTemplateTranslations.label.serumUnit', this.language);
      const sizeLabel = this.getSizeWithSizeMeasurement(size, sizeMeasurement);
      const serumDescription = getIngredientsDescription({
        ingredients: additionalData,
        $t: this.$t,
        locale: this.language,
        translationPrefix: 'messageTemplateTranslations'
      });

      return `${serumLabel} (${serumDescription}), ${usage}, ${sizeLabel}`;
    },
    getSizeWithSizeMeasurement(size, sizeMeasurement) {
      const sizeMeasurementLabel = this.$t(
        `messageTemplateTranslations.sizeLabel.${sizeMeasurement}`,
        this.language
      );

      return `${size}${sizeMeasurementLabel}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';

.treatment-composition-element {
  &__image {
    height: 65px;
  }

  &__details {
    margin-left: 12px;
  }
}

.composition-details {
  &__title {
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;

    margin-bottom: 5px;
  }

  &__description,
  &__microneedling-description {
    font-size: 12px;
    line-height: 20px;
    color: $concord-color;
  }

  &__microneedling-description {
    margin-top: 5px;
  }
}

.microneedling-description {
  &__title {
    font-weight: 600;
    color: $tuatara-color;
  }
}
</style>
